import React from 'react';

const Footer = () => {

    const renderSocials = () => {
        const socials = [
            { name: 'Github', icon: 'ti-github', url: 'https://github.com/shikhardixitprojects' },
            {
                name: 'LinkedIn',
                icon: 'ti-linkedin',
                url: 'https://www.linkedin.com/in/shikhar-dixit-39a68473'
            },
            { name: 'Email', icon: 'ti-email', url: 'mailto:shikhardixit17@gmail.com'}
        ];

        return socials.map(social => (
            <a
                target="_blank"
                href={social.url}
                key={social.icon}
                title={social.name}
                rel="noopener noreferrer"
                className="footer__social-btn">
                <i className={`${social.icon} footer__social-icon`} />
            </a>
        ));
    };

    return (
        <footer className="footer">
            <div className="footer__social">{renderSocials()}</div>
        </footer>
    );
};

export default Footer;
