import React from 'react';
import ShikiProfile from '../img/shiki.jpeg';

const Hero = () => (
    <div className="hero">
        <div className="hero__body">
            <h1 className="hero__title">Hello, I&apos;m Shikhar. Nice to meet you 👋🏼</h1>
            <img src={ShikiProfile} alt="Kenneth Kwakye-Gyamfi memoji" className="hero__memoji" />
            <a href="/ShikharDixitResume.pdf" download="" className="about__cv">
                Resume &nbsp;
                <i className="ti-file" />
            </a>
        </div>
    </div>
);

export default React.memo(Hero);
