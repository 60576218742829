import React from 'react';

import Design from '../img/computer.svg';
import MobileApp from '../img/mobile-app.svg';
import WebApp from '../img/development.svg';

const About = () => {
    const renderBreakdowns = () => {
        const breakdowns = [
            {
                img: Design,
                title: 'Frontend dev / design',
                description:
                    'I love to work actively on the user experience portion of a software development lifecycle from wireframe prototyping to frontend development.',
                subTitle: 'Things I love designing',
                subDesc: 'Wireframes, websites, prototypes',
                listTitle: 'Design Tools + Languages',
                list: [
                    'ReactJS',
                    'ReduxJS',
                    'Vanilla JS',
                    'Figma',
                    'HTML/CSS'
                ]
            },
            {
                img: WebApp,
                title: 'Backend development',
                description:
                    'I also love to get down to the dirty stuff and build APIs; websocket servers; microservice architectures; and generally full-fledged backend apps.',
                subTitle: 'Things I love building',
                subDesc: 'Web applications, APIs, DevOps',
                listTitle: 'Development tools + languages',
                list: [
                    'NodeJS',
                    'Python',
                    'Java',
                    'Google Cloud Platform',
                    'SQL/MongoDB/Google Cloud Firestore',
                    'Heroku'
                ]
            },
            {
                img: MobileApp,
                title: 'Mobile app development',
                description:
                    'I have experience developing not just Native applications but also (depending on the job) develop hybrid applications, fit for both iOS and Android.',
                subTitle: 'Types I love developing',
                subDesc: 'Android, iOS, Hybrid apps',
                listTitle: 'Development tools / Technologies',
                list: [
                    'React Native',
                    'Firebase Analytics',
                    'Push Notifications for iOS/Android'
                ]
            }
        ];

        return breakdowns.map(breakdown => (
            <div className="about__breakdown col" key={breakdown.title.toLowerCase()}>
                <img src={breakdown.img} alt={breakdown.title} className="about__breakdown-img" />
                <h3 className="about__breakdown-title">{breakdown.title}</h3>
                <p className="about__breakdown-subtitle">{breakdown.listTitle}</p>
                <ul className="about__breakdown-list">
                    {breakdown.list.map(elem => (
                        <li key={elem} className="about__breakdown-elem">
                            {elem}
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    return (
        <div className="about">
            <div className="about__inner">
                <h2 className="about__title">About me 🤔.</h2>
            </div>
            <div className="about__breakdowns grid">{renderBreakdowns()}</div>
        </div>
    );
};

export default React.memo(About);
